import { Controller } from "@hotwired/stimulus"

let newRow = undefined
let count = 0

export default class extends Controller {
  static targets = [ "row", "list" ]

  connect() {
    newRow = this.rowTarget.cloneNode(true)
    this.rowTarget.remove()
  }

  removeRow(e) {
    const row = e.currentTarget.closest(".project-row")
    row.remove()
  }

  addRow() {
    const newRowCopy = newRow.cloneNode(true)

    // Change the IDs of inputs to make sure they will be unique
    newRowCopy.querySelectorAll("input, select, textarea").forEach((input, _) => {
      if (input.id) {
        input.id = `${input.id}_${count++}`
      }
    })

    newRowCopy.classList.remove("hidden")
    this.listTarget.appendChild(newRowCopy)
  }
}
