import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "frame" ]

  loadFrame(e) {
    const frames = this.frameTargets
    const targetFrameId = e.currentTarget.dataset.frameId
    const turboFrame = frames.find((frame) => frame.id == targetFrameId )

    if (turboFrame && (!turboFrame.src || turboFrame.src === "")) {
      turboFrame.src = turboFrame.dataset.src;
    }
  }
}
